const clubs = [
  {
    clubName: "Astronomy",
    description:
      "'Twinkle, twinkle little star' just like this famous nursery rhyme, this club encourages the wondering and the wandering of the young minds. The universe is their university as they explore the celestial and deep sky objects.",
  },
  {
    clubName: "Robotics",
    description:
      "Opens a whole new world to the learners by providing new opportunities in the technological era. Exposure to robotics club for young students throughout their schooling years can increase their creativity and innovative ability. They can be more productive members of society.",
  },
  {
    clubName: "Multimedia",
    description:
      "Multimedia club explains the students to be tech-savvy, competent in the use of audio and visual equipment, with the edge to think differently and creatively. It encourages the students to express their feelings and thoughts through media messages they create. Students involved in a media club apply the skills being taught to them in the classroom into practicality.",
  },
  {
    clubName: "Dance",
    description:
      "Dance encourages creative expression comprising of controlled rhythmic movement. It strengthens the blend of health and spirituality, which aids in binding unique form of cultural beliefs. Dancing improves integrity in the young mind, lifts the mood, speeds up thought process. It creates opportunities among growing minds for self-expression & communication via dance. It enhances coordination of mind and body.",
  },
  {
    clubName: "Music",
    description:
      "Music education in school is vital for the holistic development of students as it nurtures cognitive, emotional and social skills. It promotes cultural understanding and contributes to overall wellbeing. It fosters creativity, self-confidence and emotional intelligence. Music stimulates the brain, and with its varied sounds and lyrics. Students are exposed to a large amount of vocabulary in a short amount of time. Music also provides exposure to other languages, which creates a foundation for the student's ability to understand and communicate in different languages.",
  },
  {
    clubName: "Sewa",
    description:
      "SEWA club has been formed with an aim to render meaningful service to the society. This helps to foster a sense of brotherhood and philanthropy amongst the children. The members of this club render service to society in whatever ways it is possible.",
  },
  {
    clubName: "Art",
    description:
      "Art enables artists to explore their creativity and delve into a world of limitless possibilities. With each stroke of the brush, they can experiment with various techniques, textures, and styles, giving life to their visions on canvas. Through painting, artists have the freedom to express their unique perspectives and interpretations of the world around them.",
  },
  {
    clubName: "Quiz",
    description:
      "The Quiz Club targets at grooming a set of students with good general knowledge to answer questions spontaneously when seated on the hotseat. This club helps the students to be aware of current national, international, sports and environment information.",
  },
  {
    clubName: "Heritage",
    description:
      "Heritage club was primarily formed to promote Indian Culture and develop respect for regional languages, traditions and monuments/heritage sites.",
  },
  {
    clubName: "Literary and Public Speaking",
    description:
      "This club aims to develop a flair for language and to enhance their literary skills. To think and explore creative ideas, providing opportunities to interact and improve communication skills by encouraging them to display their cognitive skills while instilling the confidence.",
  },
  {
    clubName: "Environment",
    description:
      "The objective of eco club is to create environmental awareness among students and motivate them to adopt an eco-friendly lifestyle to preserve Mother Earth.",
  },
  {
    clubName: "Interact",
    description:
      "Interact club brings together young people aged 12-18 to develop leadership skills while discovering the power of 'Service Above Self,' which is also the motto of the club. Interact is a Rotary-sponsored service club that provides young people an opportunity to participate in fun and meaningful service projects while developing leadership skills and meeting new friends. The basic principle of the Rotary Interact is to help others.",
  },
  {
    clubName: "MUN",
    description:
      "MUN club activities are an important part of maintaining a Model United Nations team while establishing a positive club atmosphere. MUN activities help develop the skills. Model United Nations club teaches and works well alongside MUN theory, lectures, and simulations.",
  },
];

export default clubs;
