import React from "react";
import data from "./data";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";

export default function ClubPage() {
  return (
    <Layout>
      <SEO
        title="Club | Chinmaya Vidyalaya"
        description=""
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="club">
        <h1 className="heading">Club Activity Report</h1>
        <br />
        <p>
          Club activities render an opportunity to correlate academic skills
          with the real-world context. Featured and creative curriculum
          facilitates children to enhance their social skills, critical thinking
          and teamwork. As a reputed CBSE school, we strive to encourage our
          students to take part in wide range of activities. Participation in
          club activities improve academic performance. It also encourages to
          explore interests and create broader perspectives with higher
          self-esteem. We have the following clubs in our school.
        </p>
        <div className="club__table">
          <div className="club__table__row club__table__row--heading">
            <div className="club__table__col1">Club Name</div>
            <div className="club__table__col2">Description</div>
          </div>
          {data.map((item, i) => (
            <div className="club__table__row">
              <div className="club__table__col1">{item.clubName}</div>
              <div className="club__table__col2">{item.description}</div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
